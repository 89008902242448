<template>
  <div id="parkRelease">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/parkLocation' }">园区选址</el-breadcrumb-item>
      <el-breadcrumb-item>发布空间</el-breadcrumb-item>
    </el-breadcrumb>    
    <!-- 步骤条 -->
    <div class="stepBox">
      <div class="stepTxt textColor">
        <span>创建空间</span>
        <span class="stepSpot textColor"></span>
      </div>
      <div class="stepConnect textColor"></div>
      <div class="stepTxt">
        <span>填充信息</span>
        <span class="stepSpot"></span>
      </div>
      <div class="stepConnect"></div>
      <div class="stepTxt">
        <span>发布</span>
        <span class="stepSpot"></span>
      </div>
    </div>
    <!-- 步骤一 -->
    <div>
      <el-form :rules="rules" ref="parkForm" :model="parkForm" label-width="80px">
        <el-form-item label="空间名称" prop="name">         
          <el-select v-if="havePark" v-model="parkForm.name" filterable remote placeholder="请输入内容空间名"
            @change="parkNameChange"
            style="width: 50%;"
            :remote-method="remoteMethod"
            :multiple-limit="1" 
            :loading="loading">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-input v-else v-model="parkForm.name" placeholder="请输入1~20个字的空间名" style="width: 50%;" minlength="1" maxlength="20" show-word-limit></el-input>
          <p class="tips" v-if="havePark" @click="establishChange">找不到适合的空间，请创建空间</p>
          <p class="tips" v-else @click="establishChange">取消创建</p>
        </el-form-item>
        <el-form-item label="空间图片">
          <div class="imgBox">
            <SuperUpload
              :uploadCardAddress="uploadLogoAddress"
              :cardplanList="image"
              @success="imgUploadSuccess"
              @CardPlanRemove="imgRemove"
              @handleExceed="handleExceed"
              :size="4194320"
              width="100%"
              height="100%"
            ></SuperUpload>
            <p class="tipsImg" v-if="!havePark">建议上传尺寸为3:2的图片</p>
            <p class="tipsImg" v-if="!havePark" style="bottom: -15px;width:250px">仅支持jpg、jpeg、png格式大小不能大于4M</p>
            <!-- 遮罩层 -->
            <div class="imgMask" v-if="havePark"></div>
          </div>
        </el-form-item>
        <div class="position" style="display: flex;align-items: center;">
          <el-form-item label="地理位置" prop="province_id">
            <el-select
              @change="provinceChange"
              v-model="parkForm.province_id"
              clearable
              placeholder="省"
              :disabled="havePark"
              style="width:120px;margin-right: 10px;"
            >
              <el-option
                v-for="item in provinces"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="city_id" label-width="0">
            <el-select
              @change="cityChange"
              v-model="parkForm.city_id"
              placeholder="市"
              clearable
              :disabled="havePark"
              style="width:120px;margin-right: 10px;"
            >
              <el-option
                v-for="item in cities"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="district_id" label-width="0">
            <el-select
              v-model="parkForm.district_id"
              placeholder="区"
              clearable
              :disabled="havePark"
              style="width:120px;margin-right: 8px;"
            >
              <el-option
                v-for="item in districts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="详细地址" prop="address" >
          <el-input v-model="parkForm.address" style="width: 50%;" :disabled="havePark" minlength="1" maxlength="40" show-word-limit></el-input>
        </el-form-item>
      </el-form>
    </div>
    <el-button style="margin-top: 12px;" @click="nextStep">下一步</el-button>
  </div>
</template>
<script>
import SuperUpload from "@/components/Upload/SuperUpload.vue";
import { success, error, warning } from "@/utils/notification";

export default {
  name: 'parkRelease',
  data() {
    return {
      havePark: true, //
      options: [], //远程列表
      uploadLogoAddress: this.URL.upLoad, //图片上传地址
      image: [], //图片列表
      provinces: [], //省
      cities: [], //市
      districts: [], //区
      parkForm: {
        name: '',
        province_id: '',
        city_id: '',
        district_id: '',
        address: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入空间名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 40 个字符', trigger: 'blur' }
        ],        
        province_id: [
          { required: true, message: '请选择省', trigger: 'change' }
        ],
        city_id: [
          { required: true, message: '请选择市', trigger: 'change' }
        ],
        district_id: [
          { required: true, message: '请选择地区', trigger: 'change' }
        ],        
      },
      queryData: [],
      loading: false
    }
  },
  components: { SuperUpload },
  methods: {
    // 切换创建
    establishChange() {
      this.havePark = !this.havePark
      this.image = []
      this.parkForm = {
        id: '',
        name: '',
        address: '',
        province_id: '',
        city_id: '',
        district_id: ''
      }
    },
    // 远程搜索
    remoteMethod(val) {
      if (val !== '') {
        this.loading = true;
				this.$http.get(this.URL.adminUrl.searchSpace, {
					params: {
						keyword: val,
					}
				}).then((res) => {
          this.queryData = res.data.data
          let list = []
          for(let i in res.data.data) {
            list.push({'value': res.data.data[i].id, 'label': (res.data.data[i].name + '('+ res.data.data[i].province.name +'·'+ res.data.data[i].city.name +'·'+ res.data.data[i].district.name +'·'+ res.data.data[i].address +')')})
          }
          this.loading = false;
          this.options = list
				})
      } else {
        this.options = [];
      }
    },
    // 远程回显
    parkNameChange(val) {
      this.image = []
      if(val) {
        for(let i in this.queryData){
          if(this.queryData[i].id == val){
            this.parkForm.space_id = this.queryData[i].id
            this.parkForm.name = this.queryData[i].name
            this.parkForm.address = this.queryData[i].address
            this.parkForm.province_id = this.queryData[i].province_id;
            this.parkForm.city_id = this.queryData[i].city_id;
            this.parkForm.district_id = this.queryData[i].district_id;
            let images = this.queryData[i].images
            if (images != null) {
              images.map(v => {
                let obj = {};
                obj.uid = "";
                obj.url = v;
                obj.path = v;
                this.image.push(obj);
              });
            }
            this.getArea1(this.parkForm.province_id)
            this.getArea2(this.parkForm.city_id)
          }
        }
      }
    },
    // 图片上传成功
    imgUploadSuccess(response, filelist, file) {
      success("图片上传成功");
      if (filelist.length > 0) {
        filelist.map(v => {
          if (v.uid === file.uid) {
            v.path = response.data.path;
          }
        });
      }
      this.image = filelist;
    },
    // 图片移除
    imgRemove(filelist) {
      this.image = filelist;
    },
    // 图片提示
    handleExceed() {
      error("只能上传1张图片");
    },
    // 根据省选择城市
    publicArea(value) {
      this.$http
        .get(this.URL.getArea, {
          params: {
            city_id: value
          }
        })
        .then(res => {
          if (value == 3246) {
            this.provinces = res.data.data;
          }
          // else {
          //   this.cities = res.data.data;
          // }
        });
    },
    //城市数据
    provinceChange(value) {
      this.$http
        .get(this.URL.getArea, {
          params: {
            city_id: value
          }
        })
        .then(res => {
          this.cities = res.data.data;
        });
      this.parkForm.city_id = "";
      this.parkForm.district_id = "";
      this.districts = [];
    },
    //地区数据
    cityChange(value) {
      if (value != "") {
        this.$http
          .get(this.URL.getArea, {
            params: {
              city_id: value
            }
          })
          .then(res => {
            this.districts = res.data.data;
          });
        this.parkForm.district_id = "";
      }
    },
    //回显显示地区
    getArea1(id) {
      this.$http
        .get(this.URL.getArea, {
          params: {
            city_id: id
          }
        })
        .then(res => {
          {
            this.cities = res.data.data;
          }
        });
    },
    getArea2(id) {
      this.$http
        .get(this.URL.getArea, {
          params: {
            city_id: id
          }
        })
        .then(res => {
          {
            this.districts = res.data.data;
          }
        });
    },
    // 下一步
    nextStep() {
      if(this.havePark){ //已有
        this.$refs['parkForm'].validate((valid) => {
          this.parkForm.image = this.image[0].path
          if (valid) {
            this.$router.push({name: 'parkEdit', params:{edit: false, data: this.parkForm}})
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      }else{ //新建
        if(this.image.length == 0){
          this.$message.error('请上传空间图片');
          return false
        }
        this.$refs['parkForm'].validate((valid) => {
          // console.log(this.parkForm)
          if (valid) {
            let imgList = this.image;
            let upImg = []
            imgList.map(v => {
              upImg.push(v.path);
            });
            this.$http.post(this.URL.adminUrl.addSpace, {
              name: this.parkForm.name,
              images: upImg,
              province_id: this.parkForm.province_id,
              city_id: this.parkForm.city_id,
              district_id: this.parkForm.district_id,
              address: this.parkForm.address,
            }).then((res) => {
              // console.log(res.data.data)
              this.parkForm.space_id = res.data.data.id
              this.parkForm.name = res.data.data.name
              this.parkForm.address = res.data.data.address
              this.parkForm.province_id = res.data.data.province_id;
              this.parkForm.city_id = res.data.data.city_id;
              this.parkForm.district_id = res.data.data.district_id;
              this.image = []
              let images = res.data.data.images
              if (images != null) {
                images.map(v => {
                  let obj = {};
                  obj.uid = "";
                  obj.url = v;
                  obj.path = v;
                  this.image.push(obj);
                });
              }
              this.getArea1(this.parkForm.province_id)
              this.getArea2(this.parkForm.city_id)

              this.parkForm.image = this.image[0].path
              this.$router.push({name: 'parkEdit', params:{edit: false, data: this.parkForm}})
            })            
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      }
    },    
  },
  created() {
    this.publicArea(3246); //获取省市
  }
}
</script>
<style lang="less" scoped>
  #parkRelease {
    .stepBox {
      width: 550px;
      display: flex;
      justify-content: space-between;
      position: relative;
      margin: 30px 0;
      .stepTxt {
        width: 65px;
        text-align: center;
        .stepSpot {
          margin: 5px auto 0;
          display: block;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: #fff;
          border: 2px solid rgb(187,187,187);
          border-color: rgb(187,187,187);
        }
      }
      .stepConnect{
        position: absolute;
        width: 233px;
        border: 2px solid rgb(187,187,187);
        border-color: rgb(187,187,187);
      }
      &>.stepConnect:nth-child(2) {
        right: 279px;
        top: 27px;
      }
      &>.stepConnect:nth-child(4) {
        right: 37px;
        top: 27px;
      }
      .textColor {
        color: rgb(64,158,255);
        border-color: rgb(64,158,255)!important;
      }
    }
    .imgBox {
      position: relative;
      height: 190px;
      .cardUpload {
        position: absolute;
        top: -30px;
      }
      .imgMask {
        width: 100%;
        height: 100%;
        position: absolute;
      }
      &::before{
        content: '*';
        display: flex;
        color: red;
        position: absolute;
        width: 5px;
        top: 3px;
        left: -80px;
        height: 5px;
      }
    }
    .position {
      .el-form-item {
        display: inline-block;
      }
    }
    .tips {
      font-size: 12px;
      color: #ed1324;
      width: 170px;
      height: 24px;
      line-height: 24px;
      margin-top: 10px;
      cursor: pointer;
    }
    .tips:hover {
      color: #409eff;
    }
    .tipsImg {
      position: absolute;
      font-size: 12px;
      color: #ed1324;
      width: 170px;
      height: 24px;
      line-height: 24px;
      bottom: 0px;
    }
  }
</style>